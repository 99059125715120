modulejs.define 'emphasis/components/date_range_calendar',
  [
    'react',
    'prop-types',
    'moment',
    'react-day-picker',
    'classnames'
  ],
  (
    React,
    PropTypes,
    moment,
    DayPicker,
    classNames
  ) ->
    # Clamp a date to the range specified by start + number of units
    clampDateToRange = (day, start, number, units) ->
      if day.diff(start, units) < number
        day
      else
        moment(start).add(number, units)

    # Wrap DayPicker to allow selecting a date range
    class DateRangeCalendar extends React.Component
      @propTypes:
        # Start date of selected range
        start: PropTypes.instanceOf(moment).isRequired
        # End date of selected range
        end: PropTypes.instanceOf(moment)
        # Maximum length of range that can be selected, in +rangeUnit+ units
        rangeLimit: PropTypes.number
        # Units for +rangeLimit+
        rangeUnits: PropTypes.oneOf(['days', 'weeks', 'months', 'years'])
        # Callback when a new range is selected, with (start, end) params
        onDateRangeSelected: PropTypes.func

      @defaultProps:
        start: moment()
        end: moment()
        rangeLimit: 1
        rangeUnits: 'years'

      constructor: (props) ->
        super(props)

        @state = this.getInitialState(props)

      getInitialState: (props) ->
        {
          start:     moment(props.start).startOf('day')
          end:       if props.end then moment(props.end).startOf('day') else null
          enteredTo: null
        }

      UNSAFE_componentWillReceiveProps: (nextProps) =>
        if this.props.start != nextProps.start || this.props.end != nextProps.end
          this.setState this.getInitialState(nextProps)

      handleCalendarContainerClick: (event) ->
        event.preventDefault()

      handleDayClick: (day, modifiers, event) =>
        event.preventDefault()
        day = moment(day).startOf('day')

        if this.state.start && this.state.end
          # If a range is selected, reset the range
          this.setState start: day, end: null, enteredTo: null
        else if day.isBefore(this.state.start, 'day')
          # If date is before the existing start, reset the range
          this.setState start: day, end: null, enteredTo: null
        else
          day = clampDateToRange(day, this.state.start, this.props.rangeLimit, this.props.rangeUnits)
          this.setState end: day, enteredTo: day
          this.props.onDateRangeSelected?(this.state.start, day)

      handleDayMouseEnter: (day) =>
        day = moment(day).startOf('day')

        if this.state.start && this.state.end
          # Range selected, do nothing
          return

        # Allow hovering after date if it's within maxDayRange days of date, otherwise clamp it
        if day.isSameOrAfter(this.state.start, 'day')
          this.setState enteredTo: clampDateToRange(day, this.state.start, this.props.rangeLimit, this.props.rangeUnits)

      render: =>
        # Compute the modifiers for React-Day-Picker

        # Selected range
        selectedDays = if this.state.start && this.state.end
          { from: this.state.start.toDate(), to: this.state.end?.toDate() }
        else if this.state.start && this.state.enteredTo
          { from: this.state.start.toDate(), to: this.state.enteredTo.toDate() }
        else
          [this.state.start.toDate()]

        # Disabled dates outside of the allowed range, but only while selecting the end date
        if this.state.start && !this.state.end
          disabledDays = [
            {before: this.state.start.toDate()},
            {after: moment(this.state.start).add(this.props.rangeLimit, this.props.rangeUnits).toDate()}
          ]

        # Markers for beginning, end
        modifiers = {
          start: this.state.start.toDate()
          end: this.state.end?.toDate()
        }

        `<div id="em-filter-menu-date-selector-calendar"
              ref={this.props.elementRef}
              className="em-date-selector-calendar"
              onKeyDown={this.handleCalendarContainerKeyDown}
              onClick={this.handleCalendarContainerClick}>
          <DayPicker showOutsideDays={true}
                     enableOutsideDaysClick={true}
                     fixedWeeks={true}
                     month={this.props.start.toDate()}
                     onDayClick={this.handleDayClick}
                     onDayMouseEnter={this.handleDayMouseEnter}
                     selectedDays={selectedDays}
                     disabledDays={disabledDays}
                     modifiers={modifiers}
          />
        </div>`
