# Interface for event button state change, on event/show
#
# This is the version for Emphasis.

# When set, forces this to be considered the active element rather than document.activeElement
# in eventAttendanceChanged.
#
# This is to support the lightbox interstitial for "change dates", in order to properly restore
# focus to the opening button afterwards.
#
# There's an interaction between the lightbox code (which tries to restore focus as of when it was opened),
# and the eventAttendanceChanged callback, where the callback happens first, then the lightbox restores focus
# to a now-missing element. Manually tracking and overriding this way allows the focus to be properly restored
# to the button after the lightbox is closed.
#
# This needs to be outside any namespaces, as both modules need to share the value.
change_dates_opener = null

namespace 'Slzr.Theme', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  EventEmitter = modulejs.require('EventEmitter')
  events = new EventEmitter
  exports.Events = events

  # Called by the server in response to event attendance changes
  #
  # options hash:
  #   event_id: ID of event changed
  #   event_instance_id: ID of event instance changed, if known. (detail page "unconfirm" has no ID)
  #   attending: new attendance state
  #   button_html: new html for this button
  #   lightbox_content: html to display in a lightbox (for ticket required, *not* date selector)
  #
  # additionaly, for detail page:
  #   attendees: new content for attendees block
  #   status: new content for event status area
  #   hide_lightbox: should a visible lightbox be closed
  exports.eventAttendanceChanged = (options) ->
    $(document).trigger 'slzr:attendance:change', options
    events.emit 'attendanceChange', options

    hideIbox() if options.hide_lightbox

    selector = '[data-event-interest]'
    selector += "[data-event-id=\"#{options.event_id}\"]"
    selector += "[data-event-instance-id=\"#{options.event_instance_id}\"]" if options.event_instance_id

    # Determine if the active button is one of the items matching the selector, and store its index if so
    buttons = document.querySelectorAll(selector)

    # This is basically buttons.indexOf(document.activeElement), but NodeList doesn't implement indexOf
    active_button = change_dates_opener || document.activeElement
    active_button_index = -1
    for index in [0..buttons.length]
      active_button_index = index if buttons[index] == active_button

    # force hover state to respective .em-card
    parent_card = active_button.closest(".em-card")
    $(parent_card).addClass("em-card--hovered")

  
    # Replace the relevant interest buttons with their updated state
    $(selector).replaceWith options.button_html

    # Restore focus to the associated, now replaced button
    if active_button_index > -1
      document.querySelectorAll(selector).item(active_button_index)?.focus()
      # If we're manually tracking an opener element, also store the new element there so focus can be restored again later
      change_dates_opener = document.querySelectorAll(selector).item(active_button_index) if change_dates_opener?
    
    # remove forced hover state from .em-card
    $(parent_card).removeClass("em-card--hovered")
    
    Slzr.Lightbox.open({padding: 0, content: options.lightbox_content}) if options.lightbox_content

    if options.attendees
      attendee_selector = "[data-event-attendees=\"#{options.event_id}\"]"
      $(attendee_selector).html options.attendees



# Event handlers for the event interest button
#
# data-action is:
#   event-going -> I'm Interested
#   event-not-going -> Not Interested
#   event-change-attendance -> Display attendance popup
#
# Gets event id from data-event-id and/or data-event-instance-id on the button.
# If an invitation is present, data-invitation is used.

namespace 'Slzr.UI.EventInterestButton', (exports, top) ->
  $ = Slzr.jQuery

  ## URLS ##

  # URL for the multi-date going lightbox
  EVENT_GOING_URL = "/event/@@EVENT_ID@@/going"

  # URL for confirming attendance
  EVENT_CONFIRM_URL = "/event/@@EVENT_ID@@/confirm"
  EVENT_UNCONFIRM_URL = "/event/@@EVENT_ID@@/unconfirm"
  EVENT_INVITE_URL = "/event/@@EVENT_ID@@/invite_friends"

  # Retrieve the event data from the button
  getEventData = (element) ->
    event_id: $(element).data('event-id')
    event_instance_id: $(element).data('event-instance-id')
    attendance_date: $(element).data('event-attendance-date')
    invitation_id: $(element).data('event-invitation')
    invitation_token: $(element).data('event-invitation-token')
    not_logged_in: !!$(element).data('post-login-action')

  # Build the appropriate URL from the specified template
  #
  # data should be same format as getEventData above
  #
  # Currently, just creates the path. Additional params are posted.
  buildUrl = (template, data) ->
    template.replace('@@EVENT_ID@@', data.event_id)

  submitInterest = (url_template, event_data) ->
    url = buildUrl url_template, event_data

    options = {}
    options['return'] = location.href
    if event_data.event_instance_id
      options['instance_id'] = event_data.event_instance_id
    else if event_data.attendance_date
      options['date'] = event_data.attendance_date

    if event_data.invitation_id and event_data.invitation_token
      options['invite'] = event_data.invitation_id
      options['token'] = event_data.invitation_token

    # Only request attendees if there's a spot to put them on the page
    options['include_attendees'] = !!$("[data-event-attendees=\"#{event_data.event_id}\"]").get(0)

    unless event_data.not_logged_in
      $.post url, options
        .done (event, xhr, settings) ->
          $(document).trigger 'slzr:attendance:change'


  # Click on Interested button for a single unattended instance (mark as going)
  $(document).on 'click', '[data-event-interest][data-event-action="event-going"]', (event) ->
    event.preventDefault()
    event_data = getEventData(this)
    submitInterest EVENT_CONFIRM_URL, event_data


  # Click on Interested button for a single attended instance (mark as not going)
  $(document).on 'click', '[data-event-interest][data-event-action="event-not-going"]', (event) ->
    event.preventDefault()
    event_data = getEventData(this)
    submitInterest EVENT_UNCONFIRM_URL, event_data


  # Click on Interested button for an event with multiple instances (change dates lightbox)
  $(document).on 'click', '[data-event-interest][data-event-action="event-change-attendance"]', (event) ->
    event.preventDefault()
    url = buildUrl EVENT_GOING_URL, getEventData(this)
    change_dates_opener = this

    openIbox url,
      onClosed: ->
        # Restore focus to the stored opener element, possibly replaced with the new state in eventAttendanceChanged
        change_dates_opener?.focus()
        change_dates_opener = null