// Override fancybox margin on emphasis
// window.default_fancybox_options['margin'] = 0;

// Lightbox hooks for emphasis
namespace('Slzr.Theme.Lightbox', function(exports, top) {

  // Return an onStart callback for fancybox for the given lightbox
  exports.makeOnStartHook = function(context, event) {
    // Only activate for login lightboxes
    if(!context || context.rel.indexOf('#login-lightbox') == -1) {
      return null;
    }

    // Return function to force the login lightbox's width to innerWidth
    return function() {
      $('#login-lightbox').width(document.documentElement.clientWidth || document.body.clientWidth);
    }
  }
});