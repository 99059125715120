# Card Size Switcher javascript
#
# Listens for *change* events on [data-action="card-size-change"] (note: this is not a *click* event, as the markup
# uses radio buttons) and swaps in the appropriate class for the card size, as specified in +data-card-size+.

namespace 'Slzr.Theme', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  $(document).on 'change', '[data-action="card-size-change"]', (event) ->
    $('.em-card-group')
      .removeClass('em-card-group--small em-card-group--medium em-card-group--big')
      .addClass("em-card-group--#{$(this).data('card-size')}")
