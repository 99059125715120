# Profile Photo editor javascript for settings page
#
# Primarily just a form handler to auto submit the chosen photo
namespace 'Slzr.Theme.SettingsProfilePhoto', (exports, top) ->
  jQuery = $ = Slzr.jQuery

  $ ->
    return unless $('[data-profile-photo]').length > 0

    $('input[data-profile-photo="file-input"]').on 'change', (event) ->
      $(this).closest('form[data-profile-photo="form"]').submit()