// Javascript handler for emphasis search bar/platform menus
namespace("Slzr.Theme.SearchBar", function (exports, top) {
  // import Localist jQuery instance
  var jQuery, $;
  jQuery = $ = require("jquery");

  // Safely add a css class +klass+ to element with +id+, if the element exists
  function safeAddClass(id, klass) {
    var element = document.getElementById(id);
    if (element !== null) {
      element.classList.add(klass);
    }
  }

  // Safely remove a css class +klass+ to element with +id+, if the element exists
  function safeRemoveClass(id, klass) {
    var element = document.getElementById(id);
    if (element !== null) {
      element.classList.remove(klass);
    }
  }

  function showSearchBarMenu() {
    document
      .getElementById("search-bar-input")
      ?.setAttribute("aria-expanded", "true");
    safeAddClass("search-bar-input", "em-input-focus");
    safeAddClass("search-bar", "em-default-focus");
    safeAddClass("sub-menu", "em-checked");
    safeAddClass("search-bar-backdrop", "em-checked");
    safeAddClass("search-bar-icon", "em-focused");
  }

  function hideSearchBarMenu() {
    document
      .getElementById("search-bar-input")
      ?.setAttribute("aria-expanded", "false");
    safeRemoveClass("search-bar-input", "em-input-focus");
    safeRemoveClass("search-bar", "em-default-focus");
    safeRemoveClass("sub-menu", "em-checked");
    safeRemoveClass("search-bar-backdrop", "em-checked");
    safeRemoveClass("search-bar-icon", "em-focus");
  }

  $(function () {
    let isShown = false;

    let searchBarEl = document.getElementById("search-bar");
    if (!searchBarEl) return;

    searchBarEl.addEventListener("focusin", (event) => {
      setTimeout(() => {
        // Don't show if focus isn't within the search bar
        if (!searchBarEl.contains(document.activeElement)) return;

        if (!isShown) {
          isShown = true;
          exports.showSearchBarMenu();
        }
      }, 0);
    });

    searchBarEl.addEventListener("focusout", (event) => {
      if (isShown) {
        setTimeout(() => {
          // Don't hide if focus is still within the element
          if (searchBarEl.contains(document.activeElement)) return;

          isShown = false;
          exports.hideSearchBarMenu();
        }, 0);
      }
    });
  });

  exports.showSearchBarMenu = showSearchBarMenu;
  exports.hideSearchBarMenu = hideSearchBarMenu;
});
