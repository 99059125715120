# Theme adapter for recent activity page updates
namespace 'Slzr.Theme.RecentActivity', (exports, top) ->
  $ = jQuery = top.Slzr.jQuery

  # Add item to the recent activity list
  #
  # options has:
  #   container: dom id (prefix) of listing
  #   item: rendered html for the item row
  exports.addItem = (options) ->
    container = options.container
    item = options.item

    $("##{container}_list").append(item)
    $("##{container}_list .em-recent-activity-nocontent").remove()

  # Remove an item from the recent activity list
  #
  # options:
  #   container: dom id (prefix) of listing
  #   item: id of element to remove (no leading #)
  exports.removeItem = (options) ->
    container = options.container
    item = options.item

    $("##{item}").remove()


  # Select reaction
  exports.selectReaction = (element) ->
    $element = $(element);
    $element.addClass('em-active')

  # Clear reactions
  exports.clearReactions = (form) ->
    $(form).find('[data-reaction]').removeClass('em-active')

  # Append more activity to the bottom of the list
  exports.appendActivity = (data) ->
    $('#' + data.container).append(data.content)

  exports.updateMoreActivityLink = (data) ->
    $('#' + data.id).html(data.content)



  # Attach form event handlers
  jQuery ($) ->
    # Attach reaction selection handler
    $('form[data-recent-activity] [data-reaction]').on 'click', (event) ->
      event.preventDefault()
      $element = $(this)
      $form = $element.closest('form')
      exports.clearReactions $form
      exports.selectReaction $element

    # Validate form on submit, and direct to the appropriate action for review or comment
    $('form[data-recent-activity]').on 'submit', (event, data) ->
      $form = $(this)
      comment = $form.find('textarea')

      if comment.attr('data-validation') == undefined
        if comment.val().isBlank()
          alert('You must provide a comment!')
          event.stopPropagation()
          event.preventDefault()

      $terms_checkbox = $form.find('[name="accept_terms"]')
      
      if $terms_checkbox.attr('data-validation') == undefined      
        unless $terms_checkbox.prop('checked')
          alert('You must accept the terms and conditions')
          event.stopPropagation()
          event.preventDefault()

      # Rewrite the form's target if a reaction is provided
      #
      # TODO: Remove this once comments/reviews are unified on the backend
      selected_reactions = $form.find('[data-reaction].em-active')
      if selected_reactions.length > 0
        # It's a review
        new_action = $form.attr('action').replace('comment', 'review')

        # And update the rating based on the reaction selected
        new_rating = switch selected_reactions.data('reaction')
          when 'up', 'heart', 'laugh' then 1
          when 'down', 'sad', 'angry' then -1
          else 0
        $form.find('input[name="rating"]').val(new_rating)
      else
        # It's a comment
        new_action = $form.attr('action').replace('review', 'comment')

      $form.attr('action', new_action)

    # Reset form state after successful submission
    $(document).delegate 'form[data-recent-activity]', 'ajax:success', (ev, data) ->
      form = $(ev.target)

      form.find('input[type="text"], textarea').val(null)
      form.find('input[type="radio"], input[type="checkbox"]').prop('checked', false)
      form.find('input[type="submit"]').each ->
        $this = $(this)
        $this.prop 'disabled', false
        original_value = $this.attr('originalValue')
        original_value or= $this.data('ujs:enable-with')
        $this.val(original_value) if original_value?

      exports.clearReactions(form)
