namespace 'Slzr.Theme.ConferenceShow', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  $ ->
    return unless $(document.body).is('.em-event_feedback--page')

    $('.em-event_feedback--toggle-sessions').click ->
      $this = $(this)
      $container = $('#' + $this.attr('aria-controls'))

      if $this.is('.em-event_feedback--toggle-sessions-open')
        # hide it
        $container.hide()
        $this.attr('aria-expanded', 'false')
          .removeClass('em-event_feedback--toggle-sessions-open')
      else
        # show it
        $container.show()
        $this.attr('aria-expanded', 'true')
          .addClass('em-event_feedback--toggle-sessions-open')

