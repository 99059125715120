# Component implementing the "When" date selection list and calendar of the filter menu
modulejs.define 'emphasis/components/filter_menu_date_selector',
  [
    'react',
    'prop-types',
    'moment',
    'classnames',
    'underscore',
    'emphasis/components/date_range_calendar',
  ], (
    React,
    PropTypes,
    moment,
    classNames,
    _,
    DateRangeCalendar
  ) ->
    # Functional component for the menu item buttons
    MenuItemButton = (props) =>
      klasses = classNames 'em-dropdown-item', props.className,
        'active': props.active

      # Extract special props for MenuItemButton
      active = props.active
      index = props.index
      label = props.label

      buttonProps = _.omit(props, 'active', 'index', 'label', 'className')

      `<button id={"em-filter-menu-date-selector-preset-" + index}
               autoFocus={active}
               className={klasses}
               tabIndex="0"
               role="menuitem"
               aria-selected={active}
               {...buttonProps}>
        {label}
      </button>`

    class FilterMenuDateSelector extends React.Component
      @propTypes:
        # Initial date selected, as a moment object. Note that the time part does not matter
        start: PropTypes.instanceOf(moment)
        # Number of days selected
        days: PropTypes.number
        # Preset options
        presets: PropTypes.arrayOf PropTypes.shape({
          # Start date for this range, as a moment object. Time part is ignored
          start: PropTypes.instanceOf(moment),
          # Number of days in this range
          days: PropTypes.number,
          # Label for this range
          label: PropTypes.string
        })
        # Platform default calendar view
        defaultCalendarView: PropTypes.shape({
            days: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired
        })
        # Custom range label
        selectRangeLabel: PropTypes.string
        # Momentjs format string for date label on button
        dateLabelFormat: PropTypes.string
        # Callback when a new date range is selected, with two params
        #   start: moment object containing the selected start date
        #   end: moment object containing the selected end date
        onDateSelected: PropTypes.func

      @defaultProps:
        start: moment()
        days: 1
        presets: [
          {start: moment(), days: 1, label: "Today"},
          {start: moment(), days: 7, label: "Next 7 Days"},
          {start: moment(), days: 30, label: "Next 30 Days"}
        ]
        defaultCalendarView: undefined
        selectRangeLabel: "Select date on a calendar"
        dateLabelFormat: 'MMMM D, YYYY'
        onDateSelected: (start, days) -> # empty

      constructor: (props) ->
        super props

        @menuRef = null
        @buttonRef = null
        @calendarRef = null

        # Flags to force a refocus on update
        @focusButton = false
        @focusCalendar = false

        # Build initial state, normalizing the start and end dates
        @state = this.getInitialState(props)

      getInitialState: (props) ->
        updated_presets = _.clone(props.presets)

        if props.defaultCalendarView
          updated_presets.push { start: moment(), days: props.defaultCalendarView.days, label: props.defaultCalendarView.label }

        selectedPreset = this.findSelectedPreset(props, updated_presets)
        calendarOpened = selectedPreset == props.presets.length
        
        return {
          start:          moment(props.start).startOf('day')
          end:            moment(props.end)?.startOf('day')
          opened:         false
          calendarOpened: calendarOpened
          # Index of "active" preset selection, or length (count + 1) for the "custom range" item
          selectedPreset: selectedPreset
          presets: updated_presets
        }

      # Find the first matching preset for the start and end provided in props
      findSelectedPreset: (props, presets, start=null, end=null) ->
        start = props.start unless start
        end = props.end unless end

        days = end.diff(start, 'd') + 1

        for preset, index in presets
          if preset.start.isSame(start, 'day') && preset.days == days
            return index

        # Default to "choose date on calendar"
        return presets.length

      UNSAFE_componentWillReceiveProps: (nextProps) ->
        # Reset internal state if the new start date or days props have changed
        if !props.start.isSame(nextProps.start, 'day') || !props.end?.isSame(nextProps.end, 'day')
          this.setState this.getInitialState(nextProps)

      componentDidMount: =>
        # Register click listener on document, to hide menu when clicking outside of it
        document.addEventListener 'click', this.handleClickOutside

      componentWillUnmount: =>
        # Remove click listener on document
        document.removeEventListener 'click', this.handleClickOutside

      componentDidUpdate: (prevProps, prevState) =>
        if prevState.selectedPreset != this.state.selectedPreset
          # Force focus on the appropriate element
          # This cheats and sets focus by ID
          this.menuRef.querySelector("#em-filter-menu-date-selector-preset-" + this.state.selectedPreset)?.focus()

        if this.state.selectedPreset == this.props.presets.length && @focusCalendar
          # Force focus on the calendar, if it's visible
          if this.calendarRef
            @focusCalendar = false
            this.calendarRef.querySelectorAll('.DayPicker-NavButton[tabindex="0"]')[0]?.focus()


        if !this.state.opened && prevState.opened != this.state.opened
          if @focusButton
            @focusButton = false
            # Menu is closing, so force focus on the button as soon as possible, but
            # not immediately to prevent escape from closing the whole menu
            theButton = this.buttonRef
            setTimeout (=> theButton.focus()), 0 if theButton?

      handleClickOutside: (event) =>
        if !event.defaultPrevented && this.menuRef && !this.menuRef.contains(event.target)
          this.toggleMenu(false)

      setMenuRef: (ref) => this.menuRef = ref
      setButtonRef: (ref) => this.buttonRef = ref
      setCalendarRef: (ref) => this.calendarRef = ref

      # Utility method to toggle menu
      #
      # If force is set, forces to that state
      toggleMenu: (force=undefined) =>
        newOpened = if force? then force else !this.state.opened

        nextState =
          opened: newOpened

        if newOpened
          # If opening, reset the active index to the most appropriate one
          if newOpened != this.state.opened
            nextState.selectedPreset = this.findSelectedPreset(this.props, this.state.presets, this.state.start, this.state.end)
            nextState.calendarOpened = nextState.selectedPreset == this.state.presets.length

        this.setState nextState

      handleMenuButtonClick: (event) =>
        this.toggleMenu()

      handleMenuButtonKeyDown: (event) =>
        return if event.defaultPrevented

        switch event.key
          # Open the menu with an up/down arrow key press
          when 'Up', 'ArrowUp', 'Down', 'ArrowDown'
            event.preventDefault()
            this.toggleMenu()
          when 'Escape'
            if this.state.opened
              event.preventDefault()
              this.toggleMenu(false)

      # Chagne the selected menu item by the offset, should be -1 or 1
      changeSelectedItem: (offset) =>
        nextIndex = this.state.selectedPreset + offset

        # Clamp to items
        if nextIndex < 0
          nextIndex = 0
        else if nextIndex > this.state.presets.length
          nextIndex = this.state.presets.length

        this.setState selectedPreset: nextIndex

      # Handle keypresses while focused in the menu
      handleMenuKeyDown: (event) =>
        return if event.defaultPrevented

        switch event.key
          when 'Up', 'ArrowUp'
            # Move selection up
            event.preventDefault()
            this.changeSelectedItem -1

          when 'Down', 'ArrowDown'
            # Move selection down
            event.preventDefault()
            this.changeSelectedItem 1

          when 'Escape'
            # Close menu and force focus on the button
            @focusButton = true
            event.preventDefault()
            this.toggleMenu(false)

          when 'Tab'
            # Close menu, but allow focusing the next item
            focusedElement = document.activeElement
            if (focusedElement.classList.contains('DayPicker-Day') && ! event.shiftKey)
              @focusButton = false
              this.toggleMenu(false)

      renderButton: =>
        label = this.state.start.format(this.props.dateLabelFormat) + ' - ' + this.state.end.format(this.props.dateLabelFormat)

        `<div>
          <i className="fas fa-sort-down"></i>
          <button type="button"
                  ref={this.setButtonRef}
                  onClick={this.handleMenuButtonClick}
                  onKeyDown={this.handleMenuButtonKeyDown}
                  className="em-select-fallback em-ellipsis placeholder"
                  aria-haspopup="true"
                  aria-controls="em-filter-menu-date-selector-menu"
                  aria-expanded={this.state.opened}
                  aria-label={"When: " + label}>
            {label}
          </button>
        </div>`

      selectDateRange: (start, end, index=null) =>
        # Update our state, and hide the menu once the selection is made
        @focusButton = true
        this.setState
          start: start
          end: end
          opened: false

        # Notify of the date change
        this.props.onDateSelected?(start, end)

      # This function returns a function with index bound appropriately, to apply the current preset
      handlePresetClick: (index) =>
        (event) =>
          event.preventDefault()

          preset = this.state.presets[index]
          this.setState
            selectedPreset: index
            calendarOpened: false

          # Calculate end date and update selected date range
          # The range returned is *inclusive*, and time is ignored completely, so a preset defined as 1 day means
          # the start and end dates are the same.
          start = moment(preset.start)
          
          days = preset.days
          
          end = start.add(days - 1, 'days')
          
          this.selectDateRange(preset.start, end)

      handleSelectRangeClick: (event) =>
        event.preventDefault()

        @focusCalendar = true

        this.setState
          selectedPreset: this.state.presets.length
          calendarOpened: true

      # Callback when the user selects a range using the calendar
      handleDateRangeSelected: (start, end) =>
        this.selectDateRange(start, end)

      renderPresets: =>
        for preset, index in this.state.presets
          isSelected = index == this.state.selectedPreset

          `<MenuItemButton key={index}
                           index={index}
                           active={isSelected}
                           onClick={this.handlePresetClick(index)}
                           role="menuitem"
                           label={preset.label} />`
      renderCalendar: =>
        if this.state.calendarOpened
          `<DateRangeCalendar elementRef={this.setCalendarRef}
                              start={this.state.start}
                              end={this.state.end}
                              onDateRangeSelected={this.handleDateRangeSelected}
          />`

      renderMenu: =>
        `<div id="em-filter-menu-date-selector-menu"
              className="em-multi-select-menu em-dropdown-container"
              onKeyDown={this.handleMenuKeyDown}>
          <fieldset className="em-multi-select-menuitems"
                    role="menu"
                    aria-activedescendant={"em-filter-menu-date-selector-preset-" + this.state.selectedPreset}>
            <legend className="sr-only">Select Date Range</legend>
            {this.renderPresets()}
            <MenuItemButton index={this.state.presets.length}
                            onClick={this.handleSelectRangeClick}
                            active={this.state.presets.length == this.state.selectedPreset}
                            className="em-dropdown-item-submenu"
                            aria-haspopup="true"
                            aria-expanded={this.state.calendarOpened}
                            aria-controls="em-filter-menu-date-selector-calendar"
                            label={this.props.selectRangeLabel} />
            {this.renderCalendar()}
          </fieldset>
        </div>`

      render: =>
        klasses = classNames 'em-select-input',
          'em-multi-select-container--open': this.state.opened

        `<div className={klasses} ref={this.setMenuRef} tabIndex={-1}>
          {this.renderButton()}
          {this.state.opened && this.renderMenu()}
        </div>`
