# Module to implement floating input labels in a non-React context
#
# This module automatically activates on page ready.
#
# See https://auralinna.blog/post/2018/how-to-create-material-design-like-form-text-fields/
modulejs.define 'slzr/floating_input_labels',
  ['jquery'],
  (jQuery) ->

    # Class name for the control's wrapper container
    WRAPPER_CLASS = 'em-input-floating'

    # Class name for the control's input element
    INPUT_CLASS = 'em-input-floating--input'

    # Class name for the control's label element
    LABEL_CLASS = 'em-input-floating--label'

    # Class name to set when element is focused
    FOCUSED_CLASS = 'em-input-floating--focused'

    # Class name to set when element is a placeholder value
    PLACEHOLDER_CLASS = 'em-input-floating--placeholder'

    # Set to true if the browser supports the :focus-within
    SUPPORTS_FOCUS_WITHIN = (->
      style = document.createElement('style')
      document.head.appendChild(style)

      try
        style.sheet.insertRule(':focus-within {}', 0)
        return true
      catch e
        false
      finally
        document.head.removeChild(style)

      return false
    )()

    # Find the wrapper of element by iterating over parents until the WRAPPER_CLASS is found
    findWrapper = (element) ->
      wrapper = element
      wrapper = wrapper.parentNode until !wrapper || wrapper.classList.contains(WRAPPER_CLASS)
      wrapper

    # Set the focused state on the nearest wrapper
    setFocused = (element, focused) ->
      wrapper = findWrapper element

      if focused
        wrapper?.classList.add FOCUSED_CLASS
      else
        wrapper?.classList.remove FOCUSED_CLASS

    setPlaceholder = (element) ->
      wrapper = findWrapper element

      if element.value == ''
        wrapper?.classList.add PLACEHOLDER_CLASS
      else
        wrapper?.classList.remove PLACEHOLDER_CLASS

    # Initialize event listeners and input state
    jQuery ($) ->
      input_selector = ".#{INPUT_CLASS}"

      # Initialize input state (mainly placeholders)
      $(input_selector).each -> setPlaceholder(this)

      # Attach event listeners
      $(document.body)
        .on 'input change', input_selector, (event) ->
          setPlaceholder(this)
          
          setPlaceholder document.getElementById('event_address') if this.name == "event_location"

      # And the focus/blur, if the browser doesn't support :focus-within
      unless SUPPORTS_FOCUS_WITHIN
        $(document.body)
          .on 'blur', input_selector, (event) ->
            setFocused(this, false)

          .on 'focus', input_selector, (event) ->
            setFocused(this, true)



# Make sure the module activates
modulejs.require('slzr/floating_input_labels')