document.addEventListener("DOMContentLoaded", () => {
  document.body.addEventListener("click", (event) => {
    const target = event.target
    
    if (target.getAttribute('data-action') === 'month-calendar-update') {
      event.preventDefault();
      
      const startDate = target.getAttribute("data-start-date");
      const componentId = target.getAttribute("data-component-id");
      const campusId = target.getAttribute("data-campus-id");
      const calendar = document.getElementById(`month-view-calendar-${componentId}`);
      
      let url = `/update_month_calendar_component/${componentId}?start_date=${startDate}`;
      
      if (campusId) {
        url += `&campus_id=${campusId}`;
      }
      
      fetch(url, {})
      .then((response) => response.text())
      .then((html) => {
        calendar.outerHTML = html;
      });
    } 
  }); 
 });