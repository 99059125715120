// Accessibility fix-ups
Slzr.jQuery(function($) {
  // Helper function to safely set an attribute on an element that may not exist
  function safeSetAttribute(element, name, value) {
    if(element) {
      return element.setAttribute(name, value);
    }
  }

  /*
   * 1. Unique IDs on share popup menus
   *
   * The share popups on event cards/pages use IDs in aria-controls to link related elements together.
   * With caching of the event cards, this can result in multiple elements having the same IDs set.
   *
   * To avoid the need to reduce the caching of the otherwise-identical event cards, this fixes up IDs on
   * each card to be unique.
   *
   * NOTE: This expects the button and related menu element to be siblings (that is, share a parent).
   */

  // Link counter
  document.querySelectorAll('[aria-controls^="event-share-buttons-"]').forEach(function(element, index) {
    // Find the nearby element
    //
    // This doesn't use nextElementSibling to allow a looser match than button + ul immediately next to each other
    var controlledElement = element.parentNode.querySelector("[id='" + element.getAttribute("aria-controls") + "']");

    if(controlledElement) {
      // Generate a hopefully-unique ID by appending the index to the end of the element
      var new_id = element.getAttribute('aria-controls') + '_' + index;
      // Update the ID of the controlled element and the aria-controls of the button the new id
      element.setAttribute('aria-controls', new_id);
      controlledElement.setAttribute('id', new_id);
    }
  });

  /*
   * 2. User settings birthday selectors
   *
   * Rails' date_select helper does not generate properly labelled <select>s, so apply appropriate labels.
   */
  document.querySelectorAll('.em-user-settings--birthday').forEach(function(element, index) {
    // Set appropriate labels for each individual input
    safeSetAttribute(document.getElementById('user_profile_birthday_1i'), 'aria-label', 'Year');
    safeSetAttribute(document.getElementById('user_profile_birthday_2i'), 'aria-label', 'Month');
    safeSetAttribute(document.getElementById('user_profile_birthday_3i'), 'aria-label', 'Day');
  })

  const flashMessage = document.querySelector(".em-flash-message");

  if (flashMessage) {
    const flashMessageClose = flashMessage.querySelector(
      ".em-flash-message-close i"
    );

    flashMessageClose.addEventListener("keydown", (e) => {
      e.preventDefault();

      if (e.code === "Enter" || e.code === "Space") {
        clearMessage();
      }
    });
  };

  const isMapOnPage = document.querySelector("#map-toggle")
  
  if (isMapOnPage) {
    document.getElementById("map-toggle").addEventListener("keydown", (e) => {
      if (e.code === "Enter" || e.code === "Space") {
        e.preventDefault();
        document.getElementById("map-toggle").click();
      }
    });

    document.getElementById("map-toggle").addEventListener("change", (e) => {
      const isMapHidden = e.target.checked
      if (isMapHidden) {
        document.getElementById("map").setAttribute("tabindex", "-1");
      } else {
        document.getElementById("map").removeAttribute("tabindex");
      };
    });
  };
});
