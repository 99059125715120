Slzr.jQuery(function ($) {
  let selects = $("select[data-action='add-tag']");

  selects.each(function (index, value) {
    $(this).selectize({
      plugins: ["selectize-plugin-a11y"],
      selectOnTab: false,
      onInitialize: function () {
        // Add arial-label to selectize input
        // This may have possible coupling issues.
        let label = this.$wrapper.parent().prev().text();
        this.$control_input[0].setAttribute("aria-label", label);
        // add id of the first option as active-descendant
        let firstOptionId = Object.keys(this.options)[0]
        this.$control_input[0].setAttribute("aria-activedescendant", firstOptionId);
      },
      onChange: function () {
        that = this;

        setTimeout(function () {
          that.setValue("");
          // return focus back to input after selection is made
          that.$control_input[0].focus();
          // hide the dropdown so it doesn't cover up selected options
          that.$dropdown.hide();
        }, 0.1);
      },
      render: {
        // Overwrite the default render of option to include the role and id attributes
        option: function (data, escape) {
          return (
            `<div role="option" class="option" id="${escape(data.value)}">${escape(data.text)}</div>`
          );
        }
      }
    });

    value.selectize.$control_input[0].onclick = function () {
      $(value.selectize.$dropdown[0]).show();
    };

    value.selectize.$control_input[0].onkeydown = function (event) {
      // Detect any typeable character OR up/down arrow
      // and show the dropdown when typed (for character search and arrow key purposes)
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      let keyCodeIsChar = event.keyCode >= 48 && event.keyCode <= 90;
      let keyCodeIsNumpad = event.keyCode >= 96 && event.keyCode <= 111;
      let keyCodeIsSpecialChar = event.keyCode >= 186 && event.keyCode <= 222;
      let keyCodeIsUpOrDownArrow = event.keyCode === 38 || event.keyCode === 40;

      if (
        keyCodeIsChar ||
        keyCodeIsNumpad ||
        keyCodeIsSpecialChar ||
        keyCodeIsUpOrDownArrow
      ) {
        $(value.selectize.$dropdown[0]).show();
      }
    };
  });
});
//