// Javascript for user menu

namespace('Slzr.Theme.UserMenu', function(exports, top) {
  // import Localist jQuery instance
  var jQuery, $, FocusTrap;
  jQuery = $ = require("jquery");
  FocusTrap = modulejs.require('slzr/modal-focus-trap')

  // Previously focused element
  var previouslyFocused = null;

  // Element of modal that's currently open
  var openedModal = null;

  // Element that caused modal to open
  var openerElement = null;

  // Handler for keydown while in modals, to capture ESC to close
  function onModalKeydown(event) {
    if(!openedModal || !openedModal.contains(event.target)) {
      return;
    }

    switch(event.key) {
      case 'Escape':
        if(!event.defaultPrevented) {
          // close modals
          exports.hideMobileMenu();
          event.preventDefault();
        }
        break;
    }
  }

  function enableEscapeListener() {
    window.addEventListener('keydown', onModalKeydown);
  }

  function disableEscapeListener() {
    window.removeEventListener('keydown', onModalKeydown);
  }

  function saveFocus() {
    previouslyFocused = document.activeElement;
  }

  function restoreFocus() {
    if(previouslyFocused) {
      previouslyFocused.focus();
      previouslyFocused = null;
    }
  }

  // Even though this is openModal, it doesn't affect styles, just focus and state tracking
  function openModal(element, opener) {
    saveFocus();
    openedModal = element;
    enableEscapeListener();
    FocusTrap.focusFirstDescendant(element);
    FocusTrap.trap(element);

    if(opener) {
      opener.setAttribute('aria-expanded', true);
      openerElement = opener;
    }
  }

  function closeModal(element) {
    // Restore focus state
    FocusTrap.untrap();
    restoreFocus();
    openedModal = false;
    disableEscapeListener();

    if(openerElement) {
      openerElement.setAttribute('aria-expanded', false);
      openerElement = false;
    }
  }


  exports.showMenu = function() {
    document.getElementById('desktop-profile-menu').classList.toggle('em-open-menu');
    document.getElementById('angle-down-icon').classList.toggle('em-open-menu');

    menuButton = document.getElementById('user-menu-button');
    chevron = document.getElementById("angle-down-icon");

    if(menuButton.getAttribute('aria-expanded') == "true") {
      menuButton.setAttribute('aria-expanded', "false");
      chevron.setAttribute('aria-expanded', "false");
      
    } else {
      menuButton.setAttribute('aria-expanded', "true");
      chevron.setAttribute('aria-expanded', "true");
    }
  };

  exports.showMobileMenu = function(element) {
    var menuElement = document.getElementById('main-menu');
    menuElement.classList.add('em-open-menu');
    setTimeout(function() {
      menuElement.classList.add('em-checked');
      openModal(menuElement, element);
    }, 0); // defer to make animate

    document.getElementById('backdrop').classList.add('em-checked');
    $('html, body').addClass('em-scroll-body');
  };

  exports.hideMobileMenu = function(element) {
    $('html, body').removeClass('em-scroll-body');
    if (document.getElementById('main-menu') !== null) {
      document.getElementById('main-menu').classList.remove('em-checked', 'em-open-menu');
    }
    if (document.getElementById('backdrop') !== null) {
      document.getElementById('backdrop').classList.remove('em-checked');
    }
    if (document.getElementById('search-bar-component') !== null) {
      document.getElementById('search-bar-component').classList.remove('em-show');
    }
    Slzr.Theme.SearchBar.hideSearchBarMenu();

    closeModal(element);
  };

  exports.showMobileSearchBar = function(element) {
    $('html, body').toggleClass('em-scroll-body');
    var searchModal = document.getElementById('search-bar-component');
    searchModal.classList.add('em-show');
    setTimeout(function() {
      openModal(searchModal, element);
    }, 0);
    document.getElementById('backdrop').classList.add('em-checked');

    if(element) {
      element.setAttribute('aria-expanded', true);
    }
  };

  $(window).on('resize', function(){
    var win = $(this);
    if (win.width() >= 768) {
      Slzr.Theme.UserMenu.hideMobileMenu();
    }
  });
});