// Code for showing and hiding 'Add to calendar' menu
Slzr.jQuery(function ($) {
  menu = "a[data-action='toggle-add-to-calendar-menu']"

  $(menu).on('click', function (evt) {
    evt.preventDefault()

    klass = 'em-showing'
    mobile = evt.target.dataset.mobile === 'true' ? '-mobile' : '';
    ariaControl = "a[aria-controls='em-buttons-item" + mobile + "']";
    buttons = $(ariaControl).next();

    if ($(buttons).hasClass(klass)) {
      $(buttons).removeClass(klass);
      $(menu).attr('aria-expanded', false);
    } else {
      $(buttons).addClass(klass);
      $(menu).attr('aria-expanded', true);
    }
  })
})
