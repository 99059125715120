// jQuery plugin to convert a <select multiple> into a dropdown list with checkboxes.
(function($) {
  "use strict";

  var pluginName = "multiSelect",
    defaults = {
      'containerHTML': '<div class="em-multi-select-container">',
      'menuHTML': '<div class="em-multi-select-menu">',
      'buttonHTML': '<span class="em-multi-select-button">',
      'menuItemsHTML': '<fieldset class="em-multi-select-menuitems">',
      'menuItemHTML': '<label class="em-multi-select-menuitem">',
      'presetsHTML': '<div class="em-multi-select-presets">',
      'modalHTML': undefined,
      'dropdownArrowHTML': '<i class="fas fa-sort-down"></i>',
      'activeClass': 'em-multi-select-container--open',
      'noneText': 'Select option',
      'allText': undefined,
      'presets': undefined,
      'positionedMenuClass': 'em-multi-select-container--positioned',
      'positionMenuWithin': undefined,
      'viewportBottomGutter': 20,
      'menuMinHeight': 200
    };

  /**
   * @constructor
   */
  function MultiSelect(element, options) {
    this.element = element;
    this.$element = $(element);
    this.settings = $.extend( {}, defaults, options );
    this._defaults = defaults;
    this._name = pluginName;
    this.init();
  }

  function arraysAreEqual(array1, array2) {
    if ( array1.length != array2.length ){
      return false;
    }

    array1.sort();
    array2.sort();

    for ( var i = 0; i < array1.length; i++ ){
      if ( array1[i] !== array2[i] ){
        return false;
      }
    }

    return true;
  }

  $.extend(MultiSelect.prototype, {

    init: function() {
      this.checkSuitableInput();
      this.findLabels();
      this.constructContainer();
      this.constructButton();
      this.constructMenu();
      this.constructModal();

      this.setUpBodyClickListener();
      this.setUpLabelsClickListener();

      this.$element.hide();
    },

    checkSuitableInput: function(text) {
      if ( this.$element.is('select[multiple]') === false ) {
        throw new Error('$.multiSelect only works on <select multiple> elements');
      }
    },

    findLabels: function() {
      this.$labels = $('label[for="' + this.$element.attr('id') + '"]');
    },

    constructContainer: function() {
      this.$container = $(this.settings['containerHTML']);
      this.$element.data('multi-select-container', this.$container);
      this.$container.insertAfter(this.$element);

      this.$dropdownArrow = $(this.settings['dropdownArrowHTML']);
      this.$container.append(this.$dropdownArrow);
    },

    // Generate a unique ID for the element, with the given suffix and index
    uniqueId: function(suffix, index=null) {
      var id = (this.$element.attr('id') || this.$element.attr('name'));
      if(suffix) id += '_' + suffix;
      if(index !== null && index !== undefined)  id += '_' + index;
      return id;
    },

    constructButton: function() {
      var _this = this;
      this.$button = $(this.settings['buttonHTML']);
      this.$button.attr({
        'aria-haspopup': 'true',
        'tabindex': 0,
        'aria-controls': _this.uniqueId('menu'),
        'aria-expanded': 'false'
      })
        .on('keydown.multiselect', function(e) {
          // Activate the menu with UP and DOWN, to match <select>s (on mac)
          // As this is a button, ENTER and SELECT are handled natively via the click event
          switch(e.key) {
            // Up and Down activate the menu
            case 'ArrowDown':
            case 'ArrowUp':
            case 'Down': // Old IE, Edge
            case 'Up': // Old IE, Edge
              e.preventDefault();
              _this.menuToggle();
              break;
          }
        })
        .on('click.multiselect', function(e) {
          // Don't let the event bubble, or Safari will also allow the label to activate
          e.stopPropagation();
          e.preventDefault();

          _this.menuToggle();
        })
        .appendTo(this.$container);

      this.$element.on('change.multiselect', function() {
        _this.updateButtonContents();
      });

      this.updateButtonContents();
    },

    updateButtonContents: function() {
      var _this = this;
      var options = [];
      var selected = [];

      this.$element.children('option').each(function() {
        var text = /** @type string */ ($(this).text());
        options.push(text);
        if ($(this).is(':selected')) {
          selected.push( $.trim(text) );
        }
      });

      this.$button.empty();

      var filter_name = this.$element.data('filter-label');

      if (selected.length == 0) {
        var none_text = filter_name ? "All " + filter_name : this.settings['noneText'];

        this.$button.addClass('placeholder')
        this.$button.text( none_text );
        this.$button.attr('aria-label', none_text);
      } else if ( (selected.length === options.length) && this.settings['allText']) {
        this.$button.removeClass('placeholder')
        this.$button.text( this.settings['allText'] );
        this.$button.attr('aria-label', this.settings['allText'] );
      } else {
        this.$button.removeClass('placeholder')
        this.$button.text( selected.join(', ') );
        this.$button.attr('aria-label', filter_name + ": " + selected.join(', '));
      }
    },

    constructMenu: function() {
      var _this = this;

      this.$menu = $(this.settings['menuHTML']);
      this.$menu.attr({
        'role': 'listbox',
        'aria-multiselectable': true,
        'id': _this.uniqueId('menu')
      }).on('keydown.multiselect', function(e) {
        switch(e.key) {
          case 'ArrowDown':
          case 'Down': // Old IE/Edge
            // Focus next element down
            e.preventDefault();
            _this.focusNextItem();
            break;
          case 'ArrowUp':
          case 'Up': // Old IE/Edge
            // Focus next element up
            e.preventDefault();
            _this.focusPreviousItem();
            break;
          case 'Tab':
            // Hide the menu, but don't prevent default behavior or stop propagation so the browser tabs to the next button
            _this.menuHide();
            break;
        }
      }).on('keydown.multiselect', function(e){
        var key = e.which;
        var escapeKey = 27;
        if (key === escapeKey) {
          e.stopPropagation();
          _this.menuHide();
        }
      })
        .appendTo(this.$container);

      this.constructMenuItems();

      if ( this.settings['presets'] ) {
        this.constructPresets();
      }
    },

    focusItem: function(element) {
      element.focus();

      // Manage active class on the current element
      if(this.$focused) this.$focused.removeClass('active');
      this.$focused = element;
      element.addClass('active');

      this.$menu.attr('aria-activedescendant', this.$focused.attr('id'));

      // Scroll the element into view, moving the least amount necessary
      var menuElement = this.$menu.get(0);
      if(menuElement.scrollHeight > menuElement.clientHeight) {
        var scrollBottom = menuElement.clientHeight + menuElement.scrollTop;
        var elementBottom = element.get(0).offsetTop + element.get(0).offsetHeight
        if(elementBottom > scrollBottom) {
          menuElement.scrollTop = elementBottom - menuElement.clientHeight;
        } else if(element.get(0).offsetTop < menuElement.scrollTop) {
          menuElement.scrollTop = element.get(0).offsetTop
        }
      }
    },

    focusNextItem: function() {
      // Find the current focused item
      var currentLabel = this.$menu.find(':focus').closest('label');
      var target = currentLabel.next('label');
      if(target.length > 0) this.focusItem(target);
    },

    focusPreviousItem: function() {
      // Find the current focused item
      var currentLabel = this.$menu.find(':focus').closest('label');
      var target = currentLabel.prev('label');
      if(target.length > 0) this.focusItem(target);
    },

    constructMenuItems: function() {
      var _this = this;

      this.$menuItems = $(this.settings['menuItemsHTML']);
      this.$menu.append(this.$menuItems);

      this.$element.on('change.multiselect', function(e, internal) {
        // Don't need to update the menu items if this
        // change event was fired by our tickbox handler.
        if(internal !== true){
          _this.updateMenuItems();
        }
      });

      this.updateMenuItems();
    },

    updateMenuItems: function() {
      var _this = this;
      this.$menuItems.empty();
      this.$menuItems.append('<legend class="sr-only">Select ' + this.$element.data('filter-label') + '</legend>');
      this.$element.children('option').each(function(option_index, option) {
        var $item = _this.constructMenuItem($(option), option_index);
        _this.$menuItems.append($item);
      });
    },

    constructPresets: function() {
      var _this = this;
      this.$presets = $(this.settings['presetsHTML']);
      this.$menu.prepend(this.$presets);

      $.each(this.settings['presets'], function(i, preset){
        var unique_id = _this.uniqueId('preset', i);
        var $item = $(_this.settings['menuItemHTML'])
          .attr({
            'for': unique_id,
            'role': 'option'
          })
          .text(' ' + preset.name)
          .appendTo(_this.$presets);

        var $input = $('<input>')
          .attr({
            'type': 'radio',
            'name': _this.$element.attr('name') + '_presets',
            'id': unique_id
          })
          .prependTo($item);

        $input.on('change.multiselect', function(){
          _this.$element.val(preset.options);
          _this.$element.trigger('change');
        });
      });

      this.$element.on('change.multiselect', function() {
        _this.updatePresets();
      });

      this.updatePresets();
    },

    updatePresets: function() {
      var _this = this;

      $.each(this.settings['presets'], function(i, preset){
        var unique_id = this._uniqueId('preset', i);
        var $input = _this.$presets.find('#' + unique_id);

        if ( arraysAreEqual(preset.options || [], _this.$element.val() || []) ){
          $input.prop('checked', true);
        } else {
          $input.prop('checked', false);
        }
      });
    },

    constructMenuItem: function($option, option_index) {
      var unique_id = this.uniqueId('item', option_index);
      var $item = $(this.settings['menuItemHTML'])
        .attr({
          'for': unique_id,
          'role': 'option',
          'id': unique_id + "_label",
          'aria-selected': false
        })
        .html($('<span>').addClass('em-clear-lh').text(' ' + $option.text()));

      var $input = $('<input>')
        .attr({
          'type': 'checkbox',
          'id': unique_id,
          'value': $option.val()
        })
        .prependTo($item);

      if ( $option.is(':disabled') ) {
        $input.attr('disabled', 'disabled');
      }
      if ( $option.is(':selected') ) {
        $input.prop('checked', 'checked');
        $item.attr('aria-selected', true);
      }

      // Toggle element on Enter
      $item.on('keydown.multiselect', function(e) {
        switch(e.key) {
          case 'Enter':
            e.preventDefault();
            $input.prop('checked', !$input.prop('checked'));
            $input.trigger('change');
            break;
        }
      });

      $input.on('change.multiselect', function() {
        if ($(this).prop('checked')) {
          $option.prop('selected', true);
          $item.attr('aria-selected', true);
        } else {
          $option.prop('selected', false);
          $item.attr('aria-selected', false);
        }

        // .prop() on its own doesn't generate a change event.
        // Other plugins might want to do stuff onChange.
        $option.trigger('change', [true]);
      });

      return $item;
    },

    constructModal: function() {
      var _this = this;

      if (this.settings['modalHTML']) {
        this.$modal = $(this.settings['modalHTML']);
        this.$modal.on('click.multiselect', function(){
          _this.menuHide();
        })
        this.$modal.insertBefore(this.$menu);
      }
    },

    setUpBodyClickListener: function() {
      var _this = this;

      // Hide the $menu when you click outside of it.
      $('#filter-dropdown').on('click.multiselect', function(){
        _this.menuHide();
      });

      // Hide the $menu when you click outside of it.
      $('html').on('click.multiselect', function(){
        _this.menuHide();
      });

      // Stop click events from inside the $button or $menu from
      // bubbling up to the body and closing the menu!
      this.$container.on('click.multiselect', function(e){
        e.stopPropagation();
      });
    },

    setUpLabelsClickListener: function() {
      var _this = this;
      this.$labels.on('click.multiselect', function(e) {
        e.preventDefault();
        e.stopPropagation();
        _this.menuToggle();
      });
    },

    menuShow: function() {
      $('html').trigger('click.multiselect'); // Close any other open menus
      this.$container.addClass(this.settings['activeClass']);

      if ( this.settings['positionMenuWithin'] && this.settings['positionMenuWithin'] instanceof $ ) {
        var menuLeftEdge = this.$menu.offset().left + this.$menu.outerWidth();
        var withinLeftEdge = this.settings['positionMenuWithin'].offset().left +
          this.settings['positionMenuWithin'].outerWidth();

        if ( menuLeftEdge > withinLeftEdge ) {
          this.$menu.css( 'width', (withinLeftEdge - this.$menu.offset().left) );
          this.$container.addClass(this.settings['positionedMenuClass']);
        }
      }

      var menuBottom = this.$menu.offset().top + this.$menu.outerHeight();
      var viewportBottom = $(window).scrollTop() + $(window).height();
      if ( menuBottom > viewportBottom - this.settings['viewportBottomGutter'] ) {
        this.$menu.css({
          'maxHeight': Math.max(
            viewportBottom - this.settings['viewportBottomGutter'] - this.$menu.offset().top,
            this.settings['menuMinHeight']
          ),
          'overflow': 'scroll'
        });
      } else {
        this.$menu.css({
          'maxHeight': '',
          'overflow': ''
        });
      }

      // Focus the first (selected) element
      this.$menu.data('focus-was', document.activeElement);
      var checked = this.$menu.find('input[type="checkbox"]:checked');
      if(!checked.get(0)) checked = this.$menu.find('input[type="checkbox"]');
      if(checked) checked = checked.first().closest('label');
      this.focusItem(checked);

      this.$button.attr('aria-expanded', true);
    },

    menuHide: function() {
      this.$container.removeClass(this.settings['activeClass']);
      this.$container.removeClass(this.settings['positionedMenuClass']);
      this.$menu.css('width', 'auto');
      // Restore focus
      if(this.$menu.data('focus-was')) this.$menu.data('focus-was').focus();
      this.$menu.data('focus-was', null);
      this.$button.attr('aria-expanded', false);
    },

    menuToggle: function() {
      if ( this.$container.hasClass(this.settings['activeClass']) ) {
        this.menuHide();
      } else {
        this.menuShow();
      }
    }

  });

  $.fn[ pluginName ] = function(options) {
    return this.each(function() {
      if ( !$.data(this, "plugin_" + pluginName) ) {
        $.data(this, "plugin_" + pluginName,
          new MultiSelect(this, options) );
      }
    });
  };

})(Slzr.jQuery);
