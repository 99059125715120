// Javascript handlers for the Filter List menu popup

namespace("Slzr.Theme.FilterList", function (exports, top) {
  // import Localist jQuery instance
  var jQuery, $, moment, React, ReactDOM, FocusTrap;
  jQuery = $ = require("jquery");
  moment = require("moment");
  React = require("react");
  ReactDOM = require("react-dom");
  FocusTrap = modulejs.require("slzr/modal-focus-trap");

  var filtersOpened = false,
    moreOptionsOpened = false,
    focusWas = null;

  function saveFocus() {
    focusWas = document.activeElement;
  }

  // Restore focus that was previously stored
  function restoreFocus() {
    // Restore focus
    if (focusWas) {
      focusWas.focus();
      focusWas = null;
    }
  }

  // Click handler to hide opened menu
  function handleClickOutside(event) {
    if (!$(event.target).closest(".em-filter").length) {
      exports.closeFilterDropdown(event, null);
      exports.closeMoreOptions(event, null);
    }
  }

  // Keydown handler to allow Escape to hide the dropdown or sidebar while it has focus
  function handleEscapeKeydown(event) {
    if (
      !event.target.closest(".em-filter-dropdown") &&
      !event.target.closest(".em-filter-button") &&
      !event.target.closest(".em-filter-more-options")
    ) {
      return;
    }

    switch (event.key) {
      case "Escape":
        if (!event.defaultPrevented) {
          exports.closeFilterDropdown(event, null);
          exports.closeMoreOptions(event, null);
          event.preventDefault();
        }
        break;
    }
  }

  // Attach event listeners for closing the filter/other items dropdowns
  function addCloseEventListeners() {
    $(document).on("click", handleClickOutside);

    // This listens on window, to avoid taking priority over React event's if this is bound more closely
    window.addEventListener("keydown", handleEscapeKeydown);
  }

  // Remove event listeners added in addCloseEventListeners
  function removeCloseEventListeners() {
    $(document).off("click", handleClickOutside);
    window.removeEventListener("keydown", handleEscapeKeydown);
  }

  // Toggle filter function
  exports.toggleFilterDropdown = function (event, element) {
    if (filtersOpened) {
      exports.closeFilterDropdown(event, element);
    } else {
      exports.openFilterDropdown(event, element);
    }
  };

  // Toggle more menu
  exports.toggleMoreOptions = function (event, element) {
    if (moreOptionsOpened) {
      exports.closeMoreOptions(event, element);
    } else {
      exports.openMoreOptions(event, element);
    }
  };

  // Open filter menu
  exports.openFilterDropdown = function (event, element) {
    filtersOpened = true;
    exports.closeMoreOptions(event, element);

    $('[data-action="toggle-filter-dropdown"]')
      .addClass("em-is-open")
      .attr("aria-expanded", "true");
    $("#filter-dropdown").fadeToggle("fast", "linear");
    var windowsize = $(window).width();
    if (windowsize < 768) {
      $("html, body").toggleClass("em-scroll-body");
    }

    // Focus the first focusable element
    // This isn't a proper search for focusable, but it works for this popup.
    saveFocus();
    $("input, select", "#filter-dropdown").get(0).focus();

    const emFilterDropdownContainer = $("#em-filter-dropdown-container").get(0);
    const filterDropdown = $("#filter-dropdown").get(0);

    // #em-filter-dropdown-container may not exist on templates modified before EVNT-1968 changes
    // so we fall back to #filter-dropdown, following the old logic before these changes

    const elementToTrap = emFilterDropdownContainer
      ? emFilterDropdownContainer
      : filterDropdown;

    FocusTrap.trap(elementToTrap);

    addCloseEventListeners();
  };

  // Close filter menu
  exports.closeFilterDropdown = function (event, element) {
    if (filtersOpened) {
      filtersOpened = false;

      FocusTrap.untrap();
      $("#filter-dropdown").hide();
      $('[data-action="toggle-filter-dropdown"]')
        .removeClass("em-is-open")
        .attr("aria-expanded", "false");
      $("html, body").removeClass("em-scroll-body");
      restoreFocus();
      removeCloseEventListeners();
    }
  };

  // Open more options menu
  exports.openMoreOptions = function (event, element) {
    moreOptionsOpened = true;
    exports.closeFilterDropdown(event, element);

    $('[data-action="toggle-more-options"]')
      .addClass("em-is-open")
      .attr("aria-expanded", "true");
    $("#more-options-dropdown")
      .hide()
      .show("slide", { direction: "right" }, 250);
    // $('#more-options-dropdown').fadeToggle( 'fast', 'linear' );
    var windowsize = $(window).width();
    if (windowsize < 768) {
      $("html, body").toggleClass("em-scroll-body");
    }

    // Focus the first focusable element
    saveFocus();
    $("input, select, a", "#more-options-dropdown").get(0).focus();
    FocusTrap.trap($("#more-options-dropdown").get(0));
    addCloseEventListeners();
  };

  // Close more options menu
  exports.closeMoreOptions = function (event, element) {
    if (moreOptionsOpened) {
      moreOptionsOpened = false;
      FocusTrap.untrap();
      $("#more-options-dropdown").hide();
      $('[data-action="toggle-more-options"]')
        .removeClass("em-is-open")
        .attr("aria-expanded", "false");
      $("html, body").removeClass("em-scroll-body");
      restoreFocus();
      removeCloseEventListeners();
    }
  };

  $(document).ready(function () {
    // Attach toggle handlers
    $('[data-action="toggle-filter-dropdown"]').on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      Slzr.Theme.FilterList.toggleFilterDropdown(event, this);
    });

    $('[data-action="close-filter-dropdown"]').on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      Slzr.Theme.FilterList.closeFilterDropdown(event, this);
    });

    $('[data-action="toggle-more-options"]').on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      Slzr.Theme.FilterList.toggleMoreOptions(event, this);
    });

    $('[data-action="close-more-options"]').on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      Slzr.Theme.FilterList.closeMoreOptions(event, this);
    });

    // Initialize the filter select elements
    $("[data-filter-select]").multiSelect({
      containerHTML: '<div class="em-select-input">',
      buttonHTML:
        '<button type="button" class="em-select-fallback em-ellipsis">',
      menuHTML: '<div class="em-multi-select-menu em-dropdown-container">',
      menuItemHTML: '<label class="em-checkbox em-dropdown-item">',
      noneText: "All"
    });

    // Initialize date selector
    var filter_form = $("[data-filter-menu]");
    // Store the base URL if it's in action
    filter_form.data("base-url", filter_form.attr("action"));

    var start = filter_form.data("filter-start-date")
      ? moment(filter_form.data("filter-start-date"))
      : moment();
    var end = filter_form.data("filter-end-date")
      ? moment(filter_form.data("filter-end-date"))
      : moment().add(6, "days");

    // Apply logic to use smart calendar URLs for event filter menus
    if (
      filter_form.data("filter-menu-type") == "events" &&
      filter_form.data("filter-menu-mode") != "search"
    ) {
      filter_form.on("submit", function (event) {
        var base_url = filter_form.data("base-url"); // base calendar URL to build from
        var view_type = "day"; // calendar "view" type, day/week/month/upcoming
        var append_date = true; // true to append start date to the URL as path segments
        var want_days = true; // true if the days parameter is wanted

        // Build the calendar URL
        //
        // When possible, this converts the user's date selection into one of our predefined types: day/week/month/upcoming
        //
        // Note that the date range the user selected is inclusive, that is, it's from start_date 00:00:00 to
        // end_date 23:59:59, so the date count is one less than may be expected.

        var start_date = filter_form.data("start-date");
        var num_days = filter_form.data("days");

        if (moment().isSame(start_date, "day")) {
          // If it's starting today, the date can be left off
          append_date = false;

          if (num_days == 29) {
            // next 30
            view_type = "upcoming";
            want_days = false;
          }
        }

        // for a week view calendar, don't include the days param
        if (num_days == 6) {
          view_type = "week";
          want_days = false;
        }

        // append the view and start date to the URL
        base_url += "/" + view_type;
        if (append_date) {
          base_url +=
            "/" +
            start_date.year() +
            "/" +
            (start_date.month() + 1) +
            "/" +
            start_date.date(); // moment's month is 0 based
        }

        // Disable the start_date input
        filter_form
          .find('[data-filter-menu-item="start_date"]')
          .prop("disabled", true);
        // Enable the days input unless it's covered by another URL segment
        filter_form
          .find('[data-filter-menu-item="days"]')
          .prop("disabled", !want_days);
        filter_form.attr("action", base_url);
      });
    }

    // TODO: Remove old date picker
    function selectedDateChanged(start, end, label) {
      // update displayed label
      $("#reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );

      // update date range fields and properties
      filter_form.data({
        "date-range": label,
        "start-date": start,
        "end-date": end,
        days: end.diff(start, "d")
      });

      $('#filter-dropdown [data-filter-menu-item="start_date"]').val(
        start.format("YYYY-MM-DD")
      );
      // The backend needs this to be a minimum of 1 for a single-date page
      $('#filter-dropdown [data-filter-menu-item="days"]').val(
        end.diff(start, "d") + 1
      );

      // hide date picker
      $(".daterangepicker").fadeOut();
    }

    // Callback for the user selecting a date range
    function handleDateSelection(start, end) {
      filter_form.data({
        "start-date": start,
        "end-date": end,
        days: end.diff(start, "d")
      });

      // Set fields the URL needs
      $('#filter-dropdown [data-filter-menu-item="start_date"]').val(
        start.format("YYYY-MM-DD")
      );
      // The backend needs this to be a minimum of 1 for a single-date page
      $('#filter-dropdown [data-filter-menu-item="days"]').val(
        end.diff(start, "d") + 1
      );
    }

    function getPlatformDefaultCalendarView() {
      var platform_default = LOCALIST_SETTINGS.default_calendar_view;

      // Labels should match platform default calendar view list language
      switch (platform_default) {
        case "three_months":
          return {
            label: "Next 3 Months",
            days: calculateDaysInRange(3, "months")
          };
        case "six_months":
          return {
            label: "Next 6 Months",
            days: calculateDaysInRange(6, "months")
          };
        case "year":
          return {
            label: "Next 12 Months",
            days: calculateDaysInRange(12, "months")
          };
      }
    }

    // Calculate specific calendar days for a time frame
    // num is number of units
    // units is 'days', 'months' or 'years
    function calculateDaysInRange(num, units) {
      var now = moment();

      return moment(now).add(num, units).diff(now, "days");
    }

    // Initialize date picker menu and mount the react component
    var dateMenuElement = $(
      '#filter-dropdown [data-filter-menu-item="date-menu"]'
    );
    if (dateMenuElement.length > 0) {
      var FilterMenuDateSelector = modulejs.require(
        "emphasis/components/filter_menu_date_selector"
      );

      // No JSX here
      ReactDOM.render(
        React.createElement(FilterMenuDateSelector, {
          start: start,
          end: end,
          onDateSelected: handleDateSelection,
          defaultCalendarView: getPlatformDefaultCalendarView()
        }),
        dateMenuElement.get(0)
      );
    }

    //TODO: Remove old date picker
    var DateRangePicker = modulejs.require("daterangepicker");
    new DateRangePicker(
      $("#reportrange"),
      {
        autoUpdateInput: false,
        autoApply: true,
        startDate: start,
        endDate: end,
        parentEl: $("#select-input-calendar"),
        showCustomRangeLabel: true,
        linkedCalendars: false,
        alwaysShowCalendars: false,
        locale: {
          customRangeLabel: "Select date on a calendar"
        },
        ranges: {
          Today: [moment(), moment()],
          // Note: last date is inclusive, so 6 days = a week, not 7
          "Next 7 days": [moment(), moment().add(6, "days")],
          "Next 30 days": [moment(), moment().add(29, "days")]
        },
        maxSpan: {
          days: 6
        }
      },
      selectedDateChanged
    );

    selectedDateChanged(start, end);
  });
}); // end namespace
