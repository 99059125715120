namespace 'Slzr.Theme.ConferenceShow', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  AGENDA_VIEW_URL = "/event/@@EVENT_ID@@/user_agenda"

  buildURL = (template, id) -> template.replace(/@@EVENT_ID@@/, id)

  $ ->
    return unless Slzr.Meta['event-kind'] == 'conference'

    # Register for an attendance change event, to trigger an update of the conference agenda
    Slzr.Theme.Events.on 'attendanceChange', (data) ->
      url = buildURL AGENDA_VIEW_URL, Slzr.Meta['event-id']
      $.get(url).done (responseText, xhr, settings) ->
        $('[data-conference-agenda]').replaceWith(responseText)

