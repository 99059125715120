namespace("Slzr.Theme.DropdownToggle", function(exports, top) {
  // import Localist jQuery instance
  var jQuery, $;
  var dropdownOpened = false
  jQuery = $ = require("jquery");
  
  const getParent = function (element) {
    return element.parentElement;
  };

  const getToggle = function (element) {
    const parent = getParent(element);
    const ariaControlsId = element.getAttribute("aria-controls");

    return parent.querySelector(`[aria-controls=${ariaControlsId}]`);
  };

  const getDropdown = function (element) {
    // Walk the tree to narrow selector in the event it is displayed
    // in more than one place on the same page
    const parent = getParent(element);
    return parent.querySelector(element.dataset.target);
  };

  exports.toggleDropdown = function(element) {
    const dropdown = getDropdown(element);
    
    if(dropdown.classList.contains("em-is-open")) {
      exports.closeDropdown(element);
    } else {
      exports.openDropdown(element);
    }
  };
  
  exports.openDropdown = function(element) {
    let dropdown = getDropdown(element);
    let toggle = getToggle(element)
    
    dropdown.classList.add("em-is-open");
    dropdown.classList.add(element.dataset.toggleClass);
    toggle.setAttribute("aria-expanded", "true");
  };

  exports.closeDropdown = function(element) {
    let dropdown = getDropdown(element);
    let toggle = getToggle(element)

    dropdown.classList.remove('em-is-open');
    dropdown.classList.remove(element.dataset.toggleClass);
    toggle.setAttribute("aria-expanded", "false");
  };

  $(document).ready(function() {
    $("[data-action='dropdown-toggle']").on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      Slzr.Theme.DropdownToggle.toggleDropdown(this);
    });
  }); // $(document).ready

}); // end namespace